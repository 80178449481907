
import MainConstants from '../../layouts/Main/Main.constants';
const { TextResponsiveWidths } = MainConstants;

const MiddleBarConstants = {
  FontSizeResponsiveConfiguration: [{
    FromWidth: TextResponsiveWidths.Small,
    value: '14px',
  },{
    FromWidth: TextResponsiveWidths.Medium,
    value: '16px',
  }],
  MissingMenuText: '<Menu sin nombre>',
  MenuTextConnector: ' - ',
};

export default MiddleBarConstants;
