
import CMS from './CMS';

import { sortIframes } from '../helpers/CustomClientSpecific/cet10/Configuration';

const preprocessConfiguration = (configuration = {}) => {
  configuration.menusTable = {};
  configuration.iframesTable = {};
  const processIframesList = (iframesList = []) => {
    iframesList.forEach( iframe => {
      configuration.iframesTable[ iframe.id] = iframe;
    });
  };
  const processMenusList = (webtvMenusList = []) => {
    webtvMenusList.forEach( webtvMenu => {
      const id = webtvMenu.id;
      configuration.menusTable[ id] = webtvMenu;
      processIframesList( webtvMenu.iframes);
      processMenusList( webtvMenu.children);

      sortIframes( webtvMenu.iframes);
    });
  };
  processMenusList( configuration.webtvMenusStructure);

  // TODO soporte para calcularse solo si alguno de los menus lo necesita
  // configuration.lastUpdated = [];
  // configuration.mostViewed = [];
  // configuration.lastUpdated.sort( (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));    // REVIEW createdAt / updatedAt / parametrizado
  // configuration.mostViewed.sort( (a, b) => b.views - a.views);

  return configuration;
};

const Configuration = {

  hostname: window.location.hostname,

  load: async (updateConfigurationCallback) => {
    try {
      const configuration = await CMS.loadConfiguration( Configuration.hostname);
      if( updateConfigurationCallback) updateConfigurationCallback( preprocessConfiguration(configuration), null);
    } catch( error) {
      error = { ...error, message: 'Error cargando la configuración.' };
      if( updateConfigurationCallback) updateConfigurationCallback( null, error);
    }
  },

};

export default Configuration;
