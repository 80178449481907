
import { test as testM3u8PlaylistUrl } from '../helpers/Network/M3u8';

export const IframeTesterIntervalMS = 10000;   // NOTE 10 segundos
export const States = {
  Online: 'Online',
  Offline: 'Offline',
};

const Iframe = {

  updateIframeState: null,
  iframesTestersIntervals: {},

  testIframeState: async (iframe) => {
    try {
      const response = await testM3u8PlaylistUrl( iframe.media && iframe.media.m3u8);
      if( Iframe.updateIframeState) Iframe.updateIframeState({ iframeId: iframe.id, state: States.Online });
    } catch( error) {
      if( Iframe.updateIframeState) Iframe.updateIframeState({ iframeId: iframe.id, state: States.Offline });
    }
  },

  updateIframesTestersIntervals: (menu, updateIframeStateCallback) => {
    Iframe.updateIframeState = updateIframeStateCallback;
    const intervalsToClear = {...Iframe.iframesTestersIntervals};
    const menusPatched = {
      'e23fa7ad-5e8d-430c-93d8-a11a54903285': 'PATCH cet10',
      '5aee12ca-1072-402a-8f16-d13148f838a8': 'PATCH cet10',
      '1aea7f2f-dae3-4806-9513-88a751951be4': 'PATCH - DEMO',
    }
    if( menu.id in menusPatched) {
      const { iframes = [] } = menu;
      iframes.forEach( iframe => {
        if( iframe.id in intervalsToClear) delete intervalsToClear[iframe.id];
        if( !(iframe.id in Iframe.iframesTestersIntervals)) {
          Iframe.iframesTestersIntervals[iframe.id] = setInterval( () => Iframe.testIframeState( iframe), IframeTesterIntervalMS);
          Iframe.testIframeState( iframe);
        }
      });
    }
    Iframe.clearIframesTestersIntervals( intervalsToClear);
  },

  clearIframesTestersIntervals: (intervalsToClear = Iframe.iframesTestersIntervals) => {
    Object.keys(intervalsToClear).forEach( iframeId => {
      const interval = intervalsToClear[iframeId];
      clearInterval( interval);
      delete Iframe.iframesTestersIntervals[iframeId];
    });
  }

};

export default Iframe;
