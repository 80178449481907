
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import ExternalConfiguration from './external-configuration';

import Error from './layouts/Error';
import Login from './layouts/Login/Login';
import Loading from './layouts/Loading/Loading';
import Main from './layouts/Main/Main';

import Configuration from './controllers/Configuration';
import Session, * as SessionConstants from './controllers/Session';

import './index.css';
import RecoverPassword from './layouts/RecoverPassword/RecoverPassword';
import NewRegister from './layouts/NewRegister/NewRegister';

const TryingTokenStates = {
  Pending: 'Pending',
  Loading: 'Loading',
  Resolved: 'Resolved',
};

class WebTv extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      configuration: null,
      sessionState: SessionConstants.States.Loading,
      sessionInfo: null,
      revokedSessionAlert: null,
      tryingTokenState: TryingTokenStates.Pending,
    };
    this.renderLoginRedirection = this.renderLoginRedirection.bind(this);
    this.setTitleAndFavicon = this.setTitleAndFavicon.bind(this);
    this.onLoginDisplayed = this.onLoginDisplayed.bind(this);
    this.renderTryValidateToken = this.renderTryValidateToken.bind(this);
    this.postRenderState = null;
  }

  setTitleAndFavicon(configuration) {
    document.title = configuration.name;   // REVIEW separar en name y siteName ??    // REVIEW buscar una solucion react pura
    const favicon = configuration.favicon ? configuration.favicon.url : null;
    if (favicon) document.getElementById('dynamic-favicon').href = favicon;   // REVIEW, buscar una solucion react pura
  }

  renderLoginRedirection(RouteProps) {
    if (this.state.sessionInfo) return '';
    const location = RouteProps.location;
    const searchParams = new URLSearchParams(location.search);
    const currentRedirect = searchParams.get(ExternalConfiguration.RedirectKey);
    if (!currentRedirect) {
      searchParams.append(ExternalConfiguration.RedirectKey, JSON.stringify(location));
    }
    const search = searchParams.toString();
    const noRedirect = location.pathname.includes('/new-register') || location.pathname.includes('/grouped_user');
    return noRedirect || <Redirect to={{ pathname: '/login', search }} />;
  }

  renderTryValidateToken(RouteProps) {
    const location = RouteProps.location;
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get(ExternalConfiguration.TokenKey);
    if (!token) this.postRenderState = { tryingTokenState: TryingTokenStates.Resolved };
    else {
      this.postRenderState = { tryingTokenState: TryingTokenStates.Loading };
      Session.validateToken(token).then(sessionInfo => {
        this.setState({ tryingTokenState: TryingTokenStates.Resolved });
      }).catch(error => {
        if (error.message) alert(`Error: ${error.message}`);
        else alert(`Error: Internal Error while validating token.`);
        this.setState({ tryingTokenState: TryingTokenStates.Resolved });
      });
    }
    return <Loading />;
  }

  render() {
    const configuration = this.state.configuration;
    if (configuration) this.setTitleAndFavicon(configuration);
    if (this.state.error) return <Error {...this.state.error} />;
    if (!configuration || this.state.sessionState === SessionConstants.States.Loading || this.state.tryingTokenState === TryingTokenStates.Loading) return <Loading />;
    if (!this.state.sessionInfo && this.state.tryingTokenState === TryingTokenStates.Pending) return <Router><Route path='/' render={this.renderTryValidateToken} /></Router>;
    return (
      <Router>
        {configuration.options && configuration.options.withLogin ? <Route path='/' render={this.renderLoginRedirection} /> : ''}
        <Switch>
          {configuration.options && configuration.options.withLogin ? <Route path='/login' render={RouteProps =>
            <Login RouteProps={RouteProps} configuration={configuration} displayed={this.onLoginDisplayed} />} /> : ''}
          <Route path='/grouped_user/change-password/:token' render={RouteProps => <RecoverPassword token={RouteProps.match.params.token} configuration={configuration} />} />
          <Route path='/new-register' render={RouteProps => <NewRegister RouteProps={RouteProps} configuration={configuration} />} />
          <Route path='/grouped_user/activate/:token' render={RouteProps => <NewRegister RouteProps={RouteProps} configuration={configuration} />} />
          <Route path='/:menuId?/:iframeId?/:autoplay?' render={RouteProps => <Main RouteProps={RouteProps} configuration={configuration} />} />
        </Switch>
      </Router>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({}));     // REVIEW incluir una optimizacion que se salte resizes muy consecutivos para mejorar rendimiento
    Configuration.load((configuration, error) => this.setState({ configuration, error }));
    Session.verifySession(({ sessionState, sessionInfo }) => {
      const mustAlert = this.state.sessionState !== SessionConstants.States.Loading && sessionState === SessionConstants.States.Revoked;
      const revokedSessionAlert = mustAlert ? ExternalConfiguration.sessionRevokedText : null;
      this.setState({ sessionState, sessionInfo, revokedSessionAlert });
    });
  }

  componentDidUpdate() {
    if (this.postRenderState) {
      const postRenderState = this.postRenderState;
      this.postRenderState = null;
      this.setState(postRenderState);
    }
  }

  onLoginDisplayed() {
    const { revokedSessionAlert } = this.state;
    if (revokedSessionAlert) {
      alert(revokedSessionAlert);
      this.setState({ revokedSessionAlert: null });
    }
  }

  componentWillUnmount() {
    Session.clearRevalidateSessionInterval();
  }

}

ReactDOM.render(<WebTv />, document.getElementById('root'));
