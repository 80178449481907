
import ExternalConfiguration, { DomainsKeys } from '../../external-configuration';

import Call, { buildApiBasePath } from './Base';

export function loadConfiguration( hostname) {
  const uri = `${buildApiBasePath(DomainsKeys.Configuration)}/public/webtv/${hostname}`;
  const options = {
    uri,
  };

  return Call.get( uri, options);
}
