
const LoginConstants = {
  cet10: {
    EmailPlaceHolder: 'correo electrónico',
    PasswordPlaceHolder: 'contraseña',
    ResetPasswordText: 'Olvidé mi contraseña',
    LogInText: 'Entrar',
  },
  groupedUser: {
    EmailPlaceHolder: 'E-mail',
    PasswordPlaceHolder: 'Password',
    ResetPasswordText: 'Forgot your password?',
    LogInText: 'Login',
    NewRegisterText: 'New Register',
  }

};

export default LoginConstants;
