import { Row, Col, Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import LoginConstants from './Login.constants';
import LoadingAnimation from "../../components/LoadingAnimation/LoadingAnimation";
import Alert from "../../components/Alert/Alert";

const LoginGroupedUser = ({ logoSrc, loginBackground, navBarColor, show, alertMessage, closeAlert, onKeyDown, logIn, emailInputOnChange, resetPassword, withRegister, passwordInputOnChange, emailInputRef, loading }) => {
    return (
        <div className='LoginFormContainer' style={{ 'backgroundImage': `url(${loginBackground})`, 'backgroundSize': 'cover' }}>
            {show && <Alert message={alertMessage} show={closeAlert} />}
            <div className='LoginForm-centered'>
                <Row>
                    {logoSrc ? <img className='LoginForm-logo w-100 h-100' src={logoSrc} /> : ''}
                </Row>
                <div className='LoginForm-background' style={{ 'backgroundColor': 'rgb(5,64,92)' }} >
                    <Col as='h1' className='text-center' style={{ 'color': 'rgb(0,155,222)' }}>
                        {LoginConstants.groupedUser.LogInText.toUpperCase()}
                    </Col>
                    < Form.Group className="mb-3" >
                        <Form.Label>{/* {t("e-mail").toUpperCase()} */} E-MAIL</Form.Label>
                        <Form.Control ref={emailInputRef}
                            className='form-control'
                            placeholder={LoginConstants.groupedUser.EmailPlaceHolder}
                            onKeyDown={onKeyDown}
                            onChange={emailInputOnChange} />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{/* {t("password").toUpperCase()} */}PASSWORD</Form.Label>
                        <Form.Control className='form-control'
                            type='password'
                            placeholder={LoginConstants.groupedUser.PasswordPlaceHolder}
                            onKeyDown={onKeyDown}
                            onChange={passwordInputOnChange}
                        />
                    </Form.Group>
                    <div className='LoginForm-buttons d-flex-row text-center'>
                        <Button
                            style={{ 'backgroundColor': navBarColor, 'fontWeight': 'bold' }}
                            type='submit'
                            className='btn btn-primary px-4 my-3'
                            onClick={logIn}
                        >
                            {LoginConstants.groupedUser.LogInText.toUpperCase()}
                        </Button>
                        <Button
                            style={{ 'color': 'rgb(0,155,222)' }}
                            variant='link'
                            className='btn-resend btn-cancelInForm w-100'
                            onClick={resetPassword}
                        >
                            {LoginConstants.groupedUser.ResetPasswordText}
                        </Button>
                        {
                            withRegister &&
                            <Button
                                style={{ 'color': 'rgb(0,155,222)' }}
                                variant='link'
                                className='btn-resend btn-cancelInForm w-100'
                                onClick={() => window.location.pathname = '/new-register'}
                            >
                                {LoginConstants.groupedUser.NewRegisterText}
                            </Button>
                        }
                    </div>
                </div>
            </div>
            {loading ? <LoadingAnimation /> : ''}
        </div >
    )
};

export default LoginGroupedUser;
