
import { loadConfiguration } from '../helpers/CMS/Configuration';

const CMS = {

  loadConfiguration: ( hostname) => {
    return loadConfiguration( hostname);
  },

};

export default CMS;
