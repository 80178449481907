import React from 'react';

import { Row, FormGroup, InputGroup, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faLock } from '@fortawesome/free-solid-svg-icons';

import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';

import LoginConstants from './Login.constants';
import './Login.css';
import Alert from '../../components/Alert/Alert';

const LoginCet10 = ({ shortLogoSrc, emailInputRef, show, alertMessage, closeAlert, onKeyDown, emailInputOnChange, passwordInputOnChange, resetPassword, logIn, loading }) => {
    return (
        <div className='LoginFormContainer'>
            {show && <Alert message={alertMessage} show={closeAlert} />}
            <div className='LoginForm-centered'>
                <Row>
                    {shortLogoSrc ? <img className='LoginForm-logo' src={shortLogoSrc} /> : ''}
                    <hr />
                </Row>
                <div className='LoginForm-background'>
                    <Row>
                        <FormGroup>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faAt} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <input ref={emailInputRef} className='form-control' placeholder={LoginConstants.cet10.EmailPlaceHolder} onKeyDown={onKeyDown} onChange={emailInputOnChange} />
                            </InputGroup>
                        </FormGroup>
                    </Row>
                    <br />
                    <Row>
                        <FormGroup>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <input className='form-control' type='password' placeholder={LoginConstants.cet10.PasswordPlaceHolder} onKeyDown={onKeyDown} onChange={passwordInputOnChange} />
                            </InputGroup>
                        </FormGroup>
                    </Row>
                    <div className='LoginForm-buttons'>
                        <Button
                            variant='link'
                            className='btn-resend btn-cancelInForm'
                            onClick={resetPassword}
                        >
                            {LoginConstants.cet10.ResetPasswordText}
                        </Button>
                        <Button
                            type='submit'
                            className='float-right btn btn-primary'
                            onClick={logIn}
                        >
                            {LoginConstants.cet10.LogInText}
                        </Button>
                    </div>
                </div>
            </div>
            {loading ? <LoadingAnimation /> : ''}
        </div>)
};

export default LoginCet10;