
import MainConstants from '../../layouts/Main/Main.constants';
const { TextResponsiveWidths } = MainConstants;

const FontSizesKeys = {
  small: 'small',
  medium: 'medium',
  big: 'big',
};

const FontSizes = {
  7: '7px',
  9: '9px',
  13: '13px',
  15: '15px',
  18: '18px',
};

const TopMenuConstants = {
  FontSizes,
  FontSizesKeys,
  FontSizesResponsiveConfigurations: {
    [FontSizesKeys.small]: [{
      FromWidth: TextResponsiveWidths.Smal, value: FontSizes[7], },{
      FromWidth: TextResponsiveWidths.Medium, value: FontSizes[9], },{
      FromWidth: TextResponsiveWidths.Big, value: FontSizes[13], }],
    [FontSizesKeys.medium]: [{
      FromWidth: TextResponsiveWidths.Smal, value: FontSizes[9], },{
      FromWidth: TextResponsiveWidths.Medium, value: FontSizes[13], },{
      FromWidth: TextResponsiveWidths.Big, value: FontSizes[15], }],
    [FontSizesKeys.big]: [{
      FromWidth: TextResponsiveWidths.Smal, value: FontSizes[13], },{
      FromWidth: TextResponsiveWidths.Medium, value: FontSizes[15], },{
      FromWidth: TextResponsiveWidths.Big, value: FontSizes[18], }],
  },
  NumberOfElementsResponsiveConfigurationsByFontSize: {   // NOTE los valores BaseFromWidth se calcularon para ajustarse a un maximo de 10 caracteres por boton para los FontSizes definidos arriba
    [FontSizes[7]]: [{ BaseFromWidth: 0, value: 1 },{ BaseFromWidth: 264, value: 2 },{ BaseFromWidth: 396, value: 3 },
      { BaseFromWidth: 528, value: 4 },{ BaseFromWidth: 660, value: 5 },{ BaseFromWidth: 792, value: 6 },
      { BaseFromWidth: 924, value: 7 },{ BaseFromWidth: 1056, value: 8 },{ BaseFromWidth: 1188, value: 9 },
      { BaseFromWidth: 1320, value: 10 },{ BaseFromWidth: 1452, value: 11 },{ BaseFromWidth: 1584, value: 12 },
      { BaseFromWidth: 1716, value: 13 },{ BaseFromWidth: 1848, value: 14 },
    ],
    [FontSizes[9]]: [{ BaseFromWidth: 0, value: 1 },{ BaseFromWidth: 330, value: 2 },{ BaseFromWidth: 495, value: 3 },
      { BaseFromWidth: 660, value: 4 },{ BaseFromWidth: 825, value: 5 },{ BaseFromWidth: 990, value: 6 },
      { BaseFromWidth: 1155, value: 7 },{ BaseFromWidth: 1320, value: 8 },{ BaseFromWidth: 1485, value: 9 },
      { BaseFromWidth: 1650, value: 10},{ BaseFromWidth: 1815, value: 11},
    ],
    [FontSizes[13]]: [{ BaseFromWidth: 0, value: 1 },{ BaseFromWidth: 440, value: 2 },{ BaseFromWidth: 660, value: 3 },
      { BaseFromWidth: 880, value: 4 },{ BaseFromWidth: 1100, value: 5 },{ BaseFromWidth: 1320, value: 6 },
      { BaseFromWidth: 1540, value: 7 },{ BaseFromWidth: 1760, value: 8 },
    ],
    [FontSizes[15]]: [{ BaseFromWidth: 0, value: 1 },{ BaseFromWidth: 492, value: 2 },{ BaseFromWidth: 738, value: 3 },
      { BaseFromWidth: 984, value: 4 },{ BaseFromWidth: 1230, value: 5 },{ BaseFromWidth: 1476, value: 6 },
      { BaseFromWidth: 1722, value: 7 },
    ],
    [FontSizes[18]]: [{ BaseFromWidth: 0, value: 1 },{ BaseFromWidth: 580, value: 2 },{ BaseFromWidth: 870, value: 3 },
      { BaseFromWidth: 1160, value: 4 },{ BaseFromWidth: 1450, value: 5 },{ BaseFromWidth: 1740, value: 6 },
    ],
  },
  MenuTypes: {
    TopMenu: 'TopMenu',
    Hybrid: 'Hybrid',
    SideBarMenu: 'SideBarMenu',
    FullMenuOnPage: 'FullMenuOnPage',
    TranslucidOverlay: 'TranslucidOverlay',
  },
  MaxTextLengthPerMenu: 12,   // NOTE para que fallas se pinte bien ha de tener al menos 11~12, cuando este valor es distinto de 10 require usar el factor de ajuste definido en TopMenu.responsive
  PreviousButtonText: ' < ',
  NextButtonText: ' > ',
};

export default TopMenuConstants;
