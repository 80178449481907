
// import ProgrammationGrid from '../ProgrammationGrid/ProgrammationGrid';
import Calendar from '../Calendar/Calendar';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import MiddleBarConstants from './MiddleBar.constants';
import './MiddleBar.css';
import ProgrammationGrid from '../ProgrammationGrid/ProgrammationGrid';

const buildMenuPath = (menu = {}) => {
  const name = menu.name || MiddleBarConstants.MissingMenuText;
  const textConnector = MiddleBarConstants.MenuTextConnector;
  const previousPath = menu.fatherWebtvMenu ? `${buildMenuPath( menu.fatherWebtvMenu)}${textConnector}` : '';
  return `${previousPath}${name}`;
};

export default function MiddleBar({ menu, pageOptions, iframe = {} }){
  const withProgrammationGrid = pageOptions.withProgrammationGrid;
  const fontSize = selectResponsiveValue( MiddleBarConstants.FontSizeResponsiveConfiguration);
  const menuPath = buildMenuPath( menu);
  return (
    <div className='middleBarContainer' style={{ '--middleBarFontSize': fontSize }}>
      {withProgrammationGrid &&
        <div>
          <ProgrammationGrid menu={menu} />
        </div>
      }
      <div className='middleBarContent'>
        { menuPath ? <div><span> {menuPath} </span><br /></div> : '' }
        { iframe.name ? <div><span> {iframe.name} </span><br /></div> : '' }
        <span>{ new Date().toLocaleDateString([],{day:'numeric',month:'long',year:'numeric'})}</span>
        { menu && menu.id === 'e23fa7ad-5e8d-430c-93d8-a11a54903285' ? // PATCH cet10
          <Calendar calendarId='4f4b4da7-81ba-4bbb-ac8e-7f1533e00a52' />
        : '' }
        { menu && menu.id === '5aee12ca-1072-402a-8f16-d13148f838a8' ? // PATCH cet10
          <Calendar calendarId='4f4b4da7-81ba-4bbb-ac8e-7f1533e00a52' />
        : '' }
        { menu && menu.id === '1aea7f2f-dae3-4806-9513-88a751951be4' ? // PATCH - DEMO
          <Calendar calendarId='dc4d5895-6baa-4b1a-b904-58df7856121e' />
        : '' }
      </div>
    </div>
  );
}

