
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import externalConfiguration from '../../external-configuration';

import ItemsList from '../ItemsList/ItemsList';
import ItemsListConstants from '../ItemsList/ItemsList.constants';
import '../ItemsList/extra.css';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import BottomSectionConstants from './BottomSection.constants';
import './BottomSection.css';

const renderIframesList = (RouteProps, mode, menu, iframes) => {
  const iframesDistribution = selectResponsiveValue(BottomSectionConstants.IframesDistributionResponsiveConfiguration);
  const paginationStyle = ItemsListConstants.PaginationStyles.Double;

  return (
    <ItemsList width={iframesDistribution.width} height={iframesDistribution.width} paginationStyle={paginationStyle}>
      {iframes.map((iframe, i) => {
        const createdAt = iframe.createdAt || iframe.media && iframe.media.createdAt || iframe.playlist && iframe.playlist.createdAt || null;
        const lastUpdate = iframe.updatedAt || iframe.media && iframe.media.updatedAtt || iframe.playlist && iframe.playlist.updatedAt || null;
        const views = iframe.views.toString() || iframe.media && iframe.media.views.toString() || null;
        const media = iframe.media || iframe.playlist && iframe.playlist.medias && iframe.playlist.medias.length && iframe.playlist.medias[0] || {};
        const thumbnail = media.thumbnail && media.thumbnail.url || externalConfiguration.noImageUrl;
        const onClick = () => {
          window.scrollTo(0, 0);
          RouteProps.history.push(`/${menu.id}/${iframe.id}/autoplay`);
        };

        return (
          <div key={i}>
            <div className='listItemAspect' onClick={onClick}>
              <img alt={iframe.name} src={thumbnail} />
              <div className='fakePlay'>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            </div>
            <div className='clickableTitle'>
              <span onClick={onClick}><b>{iframe.name}</b></span>
            </div>
            <div>
              {
                mode === 1
                  ? createdAt && <span className='text info'>{/* {BottomSectionConstants.CreationDateText} */}{new Date(createdAt).toLocaleString()}</span>
                  : <>
                    {lastUpdate && <span className='text info'>{new Date(lastUpdate).toLocaleString()}</span>}
                    {views && <span className='text info'>Views: {views}</span>}
                  </>
              }
            </div>
          </div>
        );
      })}
    </ItemsList>
  );
};

export default function BottomSection({ RouteProps, menu, menuIframes, totalIframes }) {
  const iframesDistribution = selectResponsiveValue(BottomSectionConstants.IframesDistributionResponsiveConfiguration);
  const textFontSize = selectResponsiveValue(BottomSectionConstants.TextFontSizeResponsiveConfiguration);
  const titleFontSize = selectResponsiveValue(BottomSectionConstants.TitleFontSizeResponsiveConfiguration);
  const iframesSlice = (valueToSort) => {
    return totalIframes
      .sort((a, b) => (a[valueToSort] > b[valueToSort]) ? -1 : (a[valueToSort] < b[valueToSort]) ? 1 : 0)
      .slice(0, iframesDistribution.width * iframesDistribution.width)
  }

  return (
    <div className='bottomContainer' style={{ '--BottomSectionTextFontSize': textFontSize, '--BottomSectionTitleFontSize': titleFontSize }}>
      {menuIframes.length > 1 ?
        renderIframesList(RouteProps, 1, menu, menuIframes)
        : ''}
      {menu.pageOptions.withLastUpdated ? (
        <div>
          <h2 className='listTitle'> {menu.pageOptions.lastUpdatedTitle}</h2>
          {renderIframesList(RouteProps, 2, menu, iframesSlice("updatedAt"))}
        </div>)
        : ''}
      {menu.pageOptions.withMostViewed ? (
        <div>
          <h2 className='listTitle'> {menu.pageOptions.mostViewedTitle}</h2>
          {renderIframesList(RouteProps, 2, menu, iframesSlice("views"))}
        </div>)
        : ''}
    </div>
  );
}
