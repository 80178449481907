
import ItemsList from '../ItemsList/ItemsList';
import SessionButtons from '../SessionButtons/SessionButtons';

import ItemsListConstants from '../ItemsList/ItemsList.constants';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import TopMenuConstants from './TopMenu.constants';
import TopMenuResponsive from './TopMenu.responsive';
import './TopMenu.css';

const renderListElements = (RouteProps, configuration, maxTextLengthPerMenu = 10, barColor, menuSelected) => {
  const externalLink = (link) => {
    window.location.href = link;
  }
  const fatherMenuSelected = menuSelected.fatherWebtvMenu ? menuSelected.fatherWebtvMenu : menuSelected;
  const renderBaseStructure = (element, topMenu ) => {
    return (
      <div
        key={element.id}
        className='mainMenuItem'
        style={{ 'backgroundColor': barColor, 'fontWeight': element.id === fatherMenuSelected.id && "bold" }}
      >
        <div 
          onClick={() => { 
            if(!topMenu || element.children.length === 0) RouteProps.history.push(element.pageOptions.externalLink ? `${externalLink(element.pageOptions.externalLink)}` : `/${element.id}`) 
          }}
        >
          {element.name.length <= maxTextLengthPerMenu ? element.name : `${element.name.slice(0, maxTextLengthPerMenu - 3)}...`}
        </div>
        {
          topMenu && element.children.length !== 0 && 
            <div className={`subMenu`}>
              {element.children.map(subMenu => 
                <div 
                  key={subMenu.id} 
                  className="subMenuItem" 
                  onClick={() => RouteProps.history.push(subMenu.pageOptions.externalLink ? externalLink(subMenu.pageOptions.externalLink) : `/${subMenu.id}`)}
                >
                  {subMenu.name}
                </div>
              )}
            </div>
        }
      </div>
    );
  }

  return configuration.webtvMenusStructure.map((menu) => {
    switch (configuration.options.menuType) {
      case TopMenuConstants.MenuTypes.TopMenu: return renderBaseStructure(menu, true);
      case TopMenuConstants.MenuTypes.Hybrid: return renderBaseStructure(menu, false);
      // case TopMenuConstants.MenuTypes.SideBarMenu:           // REVIEW
      // case TopMenuConstants.MenuTypes.FullMenuOnPage:        // REVIEW
      // case TopMenuConstants.MenuTypes.TranslucidOverlay:     // REVIEW
      default: return renderBaseStructure(menu, false);
    }
  });
};

export default function TopMenu({ RouteProps, configuration, menuSelected }) {
  const FontSizesResponsiveConfigurations = TopMenuConstants.FontSizesResponsiveConfigurations;
  const DefaultFontSizeKey = TopMenuConstants.FontSizesKeys.medium;
  const fontSizeKey = configuration.options && configuration.options.fontDesign && configuration.options.fontDesign.fontSize;
  const fontSizesResponsiveConfiguration = FontSizesResponsiveConfigurations[fontSizeKey] || FontSizesResponsiveConfigurations[DefaultFontSizeKey];
  const fontSize = selectResponsiveValue(fontSizesResponsiveConfiguration);
  const elements = TopMenuResponsive.getNumberOfMenus(fontSize, TopMenuConstants.MaxTextLengthPerMenu);

  const wideLogoSrc = configuration.logo ? configuration.logo.url : null;
  const shortLogoSrc = configuration.shortLogo ? configuration.shortLogo.url : null;
  const { logoSrc, logoWideClassName } = selectResponsiveValue([{
    FromWidth: 0, value: { logoSrc: shortLogoSrc, logoWideClassName: 'shortLogo' },
  }, {
    FromWidth: 1300, value: { logoSrc: wideLogoSrc, logoWideClassName: 'wideLogo' },
  }]);
  const logoClassName = ['customLogo', logoWideClassName].join(' ');
  const logoUrl = configuration.options ? configuration.options.logoUrl || null : null;     // NOTE url a la que redirige al clickar en el logo
  const barColor = configuration.options.menuColor ? configuration.options.menuColor : null;
  const buttonPrev = <span className='paginationButton previous' style={{ 'backgroundColor': barColor }}>{TopMenuConstants.PreviousButtonText}</span>;
  const buttonNext = <span className='paginationButton next' style={{ 'backgroundColor': barColor }}>{TopMenuConstants.NextButtonText}</span>;
  const paginationStyle = ItemsListConstants.PaginationStyles.InRow;

  const isCet10CustomLogin = configuration.webtvCustomLogin
    ? configuration.webtvCustomLogin.id === "b04eca71-de9d-47cd-959f-2a1e2752b9e2"
    : null

  //const test =  (menuSelected.fatherWebtvMenu === null || menuSelected.children.length === 0) ? menuSelected : menuSelected.children[0];
  //RouteProps.history.push(`/${test.id}`);

  return (
    <div>
      {logoSrc ? <div className={logoClassName}><a href={logoUrl}><img alt='' src={logoSrc} /></a></div> : ''}
      <div className='topMenuContainer' style={{ 'backgroundColor': barColor }}>
        <div className='mainMenu'>
          <ItemsList width={elements} height={1} paginationStyle={paginationStyle} buttonPrev={buttonPrev} buttonNext={buttonNext}>
            {renderListElements(RouteProps, configuration, TopMenuConstants.MaxTextLengthPerMenu, barColor, menuSelected)}
          </ItemsList>
        </div>
      </div>
      {configuration.options && configuration.options.withLogin ? <div className='topMenuSessionButtons'>
        <SessionButtons barColor={barColor} isCet10CustomLogin={isCet10CustomLogin} />
      </div> : ''}
      <div className='topMenuSpacer'></div>
    </div>
  );
}
