import React, { Component, useState } from 'react';
import { Button, Col, Row, Toast } from "react-bootstrap";

class Alert extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Row className='justify-content-center' style={{ 'zIndex': '1', 'position': 'relative' }}>
                <Col xs={6} className='d-flex justify-content-center'>
                    <Toast onClose={() => this.props.show(false)}>
                        <Toast.Header className='d-flex justify-content-between'>
                            <strong className='me-auto'>{window.location.hostname}</strong>
                        </Toast.Header>
                        <Toast.Body className='py-4 d-flex flex-column'>
                            <div className='py-2'>{this.props.message}</div>
                            <Button
                                variant='outline-secondary mt-2'
                                className='align-self-center'
                                onClick={() => this.props.show(false)}>
                                Accept
                            </Button>
                        </Toast.Body>
                    </Toast>
                </Col>
            </Row >
        )
    }
}



export default Alert;