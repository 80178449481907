
import ExternalConfiguration from '../../external-configuration';

import ItemsList from '../ItemsList/ItemsList';
import ItemsListConstants from '../ItemsList/ItemsList.constants';
import '../ItemsList/extra.css';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import MenuSelectorConstants from './MenuSelector.constants';
import './MenuSelector.css';

export default function MenuSelector({ RouteProps, menu }) {
  const { width, height } = selectResponsiveValue(MenuSelectorConstants.NumberOfElementsResponsiveConfiguration);
  const paginationStyle = ItemsListConstants.PaginationStyles.Double;
  const externalLink = (link) => {
    window.location.href = link;
  }
  return (
    <div className='menuSelectorContainer'>
      <ItemsList width={width} height={height} paginationStyle={paginationStyle}>
        {menu.children.map((subMenu, i) => {
          const thumbnail = subMenu.thumbnail && subMenu.thumbnail.url ? subMenu.thumbnail.url : ExternalConfiguration.noImageUrl;
          return (
            <div key={i}>
              <div className='listItemAspect'>
                <div onClick={() => RouteProps.history.push(subMenu.pageOptions.externalLink ? externalLink(subMenu.pageOptions.externalLink) : `/${subMenu.id}`)}>
                  <img className='listMenu' alt='' src={thumbnail} />
                </div>
              </div>
              <br />
              <span className='text title'>{subMenu.name}<br /></span>
            </div>
          );
        })}
      </ItemsList>
    </div>
  );
}
