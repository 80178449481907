
import { Component } from 'react';

import TopMenu from '../../components/TopMenu/TopMenu';
import MenuSelector from '../../components/MenuSelector/MenuSelector';
import MainPlayer from '../../components/MainPlayer/MainPlayer';
import MiddleBar from '../../components/MiddleBar/MiddleBar';
import BottomSection from '../../components/BottomSection/BottomSection';

import Iframe, * as IframeConstants from '../../controllers/Iframe';

import { selectResponsiveValue } from '../../helpers/Responsive/Responsive';

import MainConstants from './Main.constants';
import './Main.css';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {};    // NOTE este state se usa como iframesState, es decir una tabla donde las claves son iframes, y los valores indican el estado, segun IframeConstants.IframesState
    this.updateIframesTestersIntervals = this.updateIframesTestersIntervals.bind(this);
  }

  renderTopBanner(pageOptions, url) {
    return (
      <div className={pageOptions.withMainPlayerBackground ? 'topBannerBackground' : ''}>
        <div className='topBanner'><img alt='' src={url} /></div>
        <div className='margin' />
      </div>
    );
  }

  renderHeadertext(menuSelected) {
    const menu = menuSelected.fatherWebtvMenu ? menuSelected.fatherWebtvMenu : menuSelected;
    return (
      menu.pageOptions.headerFirst || menu.pageOptions.headerSecond
        ? <div key={menu.id} className='header-text' >
          <div dangerouslySetInnerHTML={{ __html: menu.pageOptions.headerFirst }} />
          <div dangerouslySetInnerHTML={{ __html: menu.pageOptions.headerSecond }} />
        </div>
        : ''
    )
  }

  renderMiddleBanner(url) {
    return (
      <div>
        <div className='middleBanner'><img alt='' src={url} /></div>
        <div className='margin' />
      </div>
    );
  }

  renderFooter(footer) {
    return (
      <div>
        <div className='margin' />
        {footer.imgUrl && <div className='footer-img'><img alt='' src={footer.imgUrl} /></div>}
        {
          footer.text &&
          <div className='footer-text' >
            {
              footer.text.first
                ? <div dangerouslySetInnerHTML={{ __html: footer.text.first }} />
                : ""
            }
            {
              footer.text.second
                ? <div dangerouslySetInnerHTML={{ __html: footer.text.second }} />
                : ""
            }
          </div>
        }
        <div className='marginFooter' />
      </div >
    );
  }

  renderContent(menu, middleBannerUrl, iframe, fontDesign) {
    const online = IframeConstants.States.Online;
    const iframesState = this.state;
    const { RouteProps, configuration } = this.props;
    const { match: { params: { menuId, iframeId } } } = RouteProps;
    const { pageOptions, iframes = [], children } = menu;
    const menusPatched = {
      'e23fa7ad-5e8d-430c-93d8-a11a54903285': 'PATCH cet10',
      '5aee12ca-1072-402a-8f16-d13148f838a8': 'PATCH cet10',
      '1aea7f2f-dae3-4806-9513-88a751951be4': 'PATCH - DEMO',
    }
    const iframesOnline = !(menu.id in menusPatched) ? iframes : iframes.filter(iframe => iframe.id in iframesState && iframesState[iframe.id] === online);
    
    if (!iframeId) iframe = iframesOnline[0];
    if (!menuId) {
      const defaultMenu = (menu.fatherWebtvMenu === null && menu.children.length === 0) ? menu : menu.children[0];
      RouteProps.history.push(`/${defaultMenu.id}`);
    }

    return (
      <div>
        <MainPlayer RouteProps={RouteProps} pageOptions={pageOptions} iframe={iframe} fontDesign={fontDesign} />
        <div className={`margin${pageOptions.withMainPlayerBackground ? ' backgroundMainPlayer' : ''}`} />
        <MiddleBar menu={menu} pageOptions={pageOptions} iframe={iframe} />
        <div className='margin' />
        {middleBannerUrl && middleBannerUrl !== '' ? this.renderMiddleBanner(middleBannerUrl) : ''}
        <BottomSection RouteProps={RouteProps} menu={menu} menuIframes={iframesOnline} totalIframes={Object.values(configuration.iframesTable)} />
      </div>
    );
  }

  render() {
    const { RouteProps, configuration } = this.props;
    const { match: { params: { menuId, iframeId } } } = RouteProps;
    const menu = configuration.menusTable[menuId] || configuration.webtvMenusStructure[0];
    const onMenuSelector = menu.children && menu.children.length > 0 && configuration.options.menuType === "Hybrid";
    const iframe = configuration.iframesTable[iframeId];

    const fontDesign = configuration.options.fontDesign;
    const mainBackground = configuration.background ? configuration.background.url : null;
    const topBanner = configuration.topBanner ? configuration.topBanner.url : null;
    const middleBanner = configuration.middleBanner ? configuration.middleBanner.url : null;
    const footer = {
      imgUrl: configuration.footer ? configuration.footer.url : null,
      text: configuration.options.footerText ? configuration.options.footerText : null,
      //link: configuration.options.footerLink ? configuration.options.footerLink : null
    };


    const mainColumnWidth = selectResponsiveValue(MainConstants.MainColumnWidthResponsiveConfiguration);
    return (
      <div className='mainBackground'
        style={{
          'backgroundImage': `url(${mainBackground})`,
          '--mainColumnWidth': mainColumnWidth,
          '--mainFont': fontDesign.font,
          '--mainFontSize': fontDesign.fontSize
        }}>
        <TopMenu RouteProps={RouteProps} configuration={configuration} menuSelected={menu} />
        {topBanner && topBanner !== '' ? this.renderTopBanner(menu.pageOptions, topBanner) : ''}
        {this.renderHeadertext(menu)}
        {onMenuSelector ? <MenuSelector RouteProps={RouteProps} menu={menu} /> : this.renderContent(menu, middleBanner, iframe, fontDesign)}
        {footer && footer !== '' ? this.renderFooter(footer) : ''}
      </div>
    );
  }

  updateIframesTestersIntervals() {
    const { RouteProps: { match: { params: { menuId } } }, configuration } = this.props;
    const menu = configuration.menusTable[menuId] || configuration.webtvMenusStructure[0];
    const iframesState = this.state;

    Iframe.updateIframesTestersIntervals(menu, ({ iframeId: id, state }) => {
      if (!(id in iframesState) || state !== iframesState[id]) {
        this.setState({ [id]: state });
      }
    });
  }

  componentDidUpdate() {
    this.updateIframesTestersIntervals();
  }

  componentDidMount() {
    this.updateIframesTestersIntervals();
  }

  componentWillUnmount() {
    Iframe.clearIframesTestersIntervals();
  }

}

export default Main;
