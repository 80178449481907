
import MainConstants from '../../layouts/Main/Main.constants';
const { TextResponsiveWidths } = MainConstants;

const MainPlayerConstants = {
  NoContentFontSizeResponsiveConfiguration: [{
    FromWidth: TextResponsiveWidths.Small,
    value: '12px',
  },{
    FromWidth: TextResponsiveWidths.Medium,
    value: '14px',
  }],
  NoContentText: 'En este momento no hay ningún directo en emisión.'
};

export default MainPlayerConstants;
