
export function test( playlistUrl) {
  const baseUrl = playlistUrl.split('/').slice(0,-1).join('/');
  return fetch( playlistUrl).then( response => {
    if( !response.ok) return Promise.reject( response);
    return response.text();
  }).then( response => {
    const chunklistPath = response.split('\n').reduce( (prev, next) => prev || next[0] !== '#' && next, null);
    if( !chunklistPath) return Promise.reject({ message: 'm3u8 without chunklist' });
    const chunklistUrl = `${baseUrl}/${chunklistPath}`;
    return fetch( chunklistUrl, { method: 'HEAD' });
  }).then( response => {
    if( !response.ok) return Promise.reject( response);
    return response;
  })
}
